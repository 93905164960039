.App {
  background-color: #282c34;
  padding: 0.25in;
  min-height: 100%;
}

.msg {
  text-align: center;
  padding-top: 2in;
  font-size: 16px;
}