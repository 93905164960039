.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 9in;
  height: 50px;
  background: #fafafa;
  z-index: 999;
  box-shadow: 1px 5px 5px black;  
}

.toolbar .btn {
  margin-left: 0.25in;
  line-height: 50px;
  margin-right: 0.25in;
}

.toolbar .changes_info {
  float: right;
  padding-right: 0.25in;
  line-height: 50px;
}

.toolbar .changes_info.dirty {
  color: red;
}

.toolbar .changes_info.saved {
  color: green;
}

.toolbar .changes_info.saving {
  color: orange;
}