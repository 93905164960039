div.list {
  width: 8in;
  transform-origin: top left;
  margin-bottom: 0.25in;
  background: white;
  padding: 0.25in;
  padding-top: 50px;
  position: relative;
}

div.list table th {
  font-weight: bold;
  text-align: left;
  background-color: #eee;
  border-bottom: 1px solid black;
}

div.list table th input[type="text"] {
  width: 90%;
}

div.list table td {
  text-align: left;
}

div.list table tbody tr:nth-child(even) {
  background-color: #eee;
}