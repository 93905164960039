.page {
  width: 7in;
  height: 9.5in;
  /* transform: scale(1.94, 1.94); */
  transform-origin: top left;
  margin-bottom: 0.25in;
  background: white;
  padding: 0.75in;
  position: relative;
}

.page.cover > div.header {
  height: 2.5in;
  background: #333;
}

.page.cover div.business_info {
  position: absolute;
  bottom: 0.75in;
  left: 0.75in;
  font-size: 16px;
}

.page.cover div.extra_info {
  position: absolute;
  bottom: 0.75in;
  right: 0.75in;
  font-size: 16px;
  text-align: right;
  min-width: 100px;
}

.page.cover div.business_info span.sub {
  font-size: 16px;
}

.page.cover div.address {
  padding: 0.15in;
  padding-top: 0.75in;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
}

.page.cover span.address_sub {
  font-weight: 200;
  font-size: 28px;
}

.page.cover span.date {
  font-weight: 200;
  font-size: 24px;
}

.page div.photos {
  text-align: center;
}

.page div.photos div.photo {
  width: 2.25in;
  height: 1.5in;
  border: 1pt dashed rgba(0, 0, 0, 0);
  position: relative;
  display: inline-block;
  margin-right: 0.05in;
}

.page div.photos div.photo:last-child {
  margin-right: 0;
}

.page div.photos div.photo img {
  width: 2.25in;
  height: 1.5in;
  border: 1px solid black;
}

.page div.photos div.photo.none .tools {
  display: block;
}

.page div.photos div.photo .tools {
  width: 2.25in;
  height: 1.5in;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  line-height: 1.5in;
  display: none;
  background: rgba(255, 255, 255, 0.9);
  border: 1pt dashed #aaa;
}

.page div.photos div.photo:hover .tools {
  display: block;
}

.page div.photos div.photo .tools input[type="file"] {
  width: 2.25in;
  height: 1.5in;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  line-height: 1.5in;
  opacity: 0;
  cursor: pointer;
}

.page .header {
  position: absolute;
  top: 0.3in;
  left: 0.75in;
  right: 0.75in;
  text-align: center;
}

.page .footer {
  position: absolute;
  bottom: 0.3in;
  left: 0.75in;
  right: 0.75in;
  font-size: 10pt;
  text-align: center;
}

.left {
  float: left;
  text-align: left;
}
.right {
  float: right;
  text-align: right;
}

div.tbl {
  margin-top: 0.15in;
}
div.tbl table td {
  vertical-align: top;
}

.label {
  font-weight: bold;
  text-align: left;
  font-size: 10pt;
}

div.tbl table td {
  text-align: left;
  padding-bottom: 0.05in;
  padding-right: 0.25in;
  position: relative;
}

div.tbl table td:last-child {
  padding-right: 0;
}

div.dropdown_wrap {
  position: absolute;
  z-index: 100;
  border: 1px solid black;
  width: 3in;
  background-color: white;
  padding: 0;
  margin: 0;
  max-height: 2in;
  overflow: scroll;
  top: 100%;
}

div.dropdown_wrap > input[type="text"] {
  width: 3in;
  margin: 0;
  border: none;
  border-bottom: 1px solid #666;
  padding: 0.05in;
}

div.dropdown_wrap > div {
  padding: 0.05in;
  cursor: pointer;
}

div.notice {
  margin-bottom: 0.5in;
}

div.notice div.title {
  color: red;
  font-weight: bold;
  margin-bottom: 0.1in;
}

.betweenpages {
  text-align: center;
  width: 8.5in;
  padding-bottom: 0.25in;
}

.belowpage {
  text-align: right;
  width: 8.5in;
  margin-top: -0.25in;
  margin-bottom: 0.25in;
}

.betweenpages a {
  color: cyan;
}

.belowpage a {
  color: cyan;
}