html, body, td, th {
  font-family: 'Hind Madurai', sans-serif;
  font-size: 12pt;
  font-weight: 200;
}

body, html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.inline_preview .input_line { background-color: white !important; }
body.inline_preview .noprint { display: none !important; }
body.inline_preview .betweenpages { display: none !important; }
body.inline_preview .belowpage { display: none !important; }
body.inline_preview .tools { display: none !important; }
body.inline_preview .page div.photos div.photo { border: 0 !important; }

@media print {
  body { margin: none !important; }
  .App { padding: 0 !important; }
  .input_line { background-color: white !important; }
  .page { margin: 0 !important; }
  .betweenpages { display: none !important; }
  .belowpage { display: none !important; }
  .noprint { display: none !important; }
  .toolbar { display: none !important; }
  .tools { display: none !important; }
  .page div.photos div.photo { border: 0 !important; }
}

@page {
  size: auto;
  margin: 0 0 0 0;
}
