.input_line {
  background-color: bisque;;
}

.input_line.inline {
  display: inline-block;
}

.input_line.inline.novalue {
  min-width: 0.25in;
}